var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel(false)
                        },
                      },
                    },
                    [_vm._v(" cancel ")]
                  ),
                  _vm.canEditSetupTables
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: {
                            color: "green",
                            dark: "",
                            "data-cy": "vehicle-save-btn",
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" save ")]
                      )
                    : _vm._e(),
                  _vm.me.is.superAdmin && _vm.value.synced
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right mr-2",
                          attrs: {
                            color: "primary",
                            disabled: _vm.value.cannotDelete,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.hide()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { small: "" } },
                            [
                              _vm._v(
                                "mdi-" +
                                  _vm._s(_vm.value.hidden ? "eye" : "eye-off")
                              ),
                            ]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.value.hidden ? "Unhide" : "Hide") +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-form",
            {
              attrs: {
                readonly: _vm.value.id > 0 && !_vm.canEditSetupTables,
                "data-cy": "vehicle-form",
              },
            },
            [
              _c(
                "v-container",
                [
                  _vm.value.synced
                    ? _c(
                        "v-alert",
                        { attrs: { outlined: "", type: "info", text: "" } },
                        [
                          _vm._v(
                            " This Vehicle is synced with EZRouting. Some fields will not be able to be modified here. "
                          ),
                        ]
                      )
                    : !_vm.value.id && _vm.isRoutingClient
                    ? _c(
                        "v-alert",
                        { attrs: { outlined: "", type: "info", text: "" } },
                        [
                          _vm._v(
                            " This Vehicle will only be avilable in EZActivityTrips. If you would like this Vehicle to be available in EZRouting, please add it there first and it will be synced with EZActivityTrips. "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              value: _vm.value.active,
                              readonly: _vm.value.synced,
                              label: "Active",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.active.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.active.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.active, "$model", $$v)
                              },
                              expression: "$v.form.active.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.name,
                              label: "Name",
                              readonly: _vm.value.synced,
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.name
                              ),
                              "data-cy": "vehicle-name",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.name.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.name,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.name.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-select", {
                            staticClass: "mb-0",
                            attrs: {
                              label: "Type",
                              value: _vm.value.type,
                              readonly: _vm.value.synced,
                              items: _vm.vehicleTypes,
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.type
                              ),
                              "menu-props": { bottom: true, offsetY: true },
                              required: "",
                              "data-cy": "vehicle-type",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.type.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.type.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.type, "$model", $$v)
                              },
                              expression: "$v.form.type.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-select", {
                            staticClass: "mb-0",
                            attrs: {
                              label: "Location",
                              value: _vm.value.depotId,
                              items: _vm.vehicleLocations,
                              "item-text": "name",
                              "item-value": "depotId",
                              outlined: "",
                              required: "",
                              readonly: _vm.value.synced,
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.depotId
                              ),
                              "menu-props": { bottom: true, offsetY: true },
                              "data-cy": "vehicle-location",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.depotId.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.depotId.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.depotId, "$model", $$v)
                              },
                              expression: "$v.form.depotId.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-select", {
                            staticClass: "mb-0",
                            attrs: {
                              label: "Driver",
                              value: _vm.value.driverId,
                              items: _vm.drivers,
                              "item-text": _vm.getDriverName,
                              "item-value": "id",
                              outlined: "",
                              clearable: "",
                              readonly: _vm.value.synced,
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.driverId
                              ),
                              "menu-props": { bottom: true, offsetY: true },
                            },
                            model: {
                              value: _vm.$v.form.driverId.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.driverId, "$model", $$v)
                              },
                              expression: "$v.form.driverId.$model",
                            },
                          }),
                          _vm.upcomingAssignments?.vehicleId
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "mb-0",
                                  attrs: {
                                    outlined: "",
                                    type: "warning",
                                    text: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " This vehicle has an upcoming assignment with Trip Request #" +
                                      _vm._s(
                                        _vm.upcomingAssignments.tripRequestId
                                      ) +
                                      " on " +
                                      _vm._s(
                                        _vm.convertDateFormat(
                                          _vm.upcomingAssignments
                                            .vehPickupDate +
                                            " " +
                                            _vm.upcomingAssignments
                                              .vehPickupTime
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.capacityElem,
                              label: "Capacity (Elementary)",
                              type: "number",
                              min: "0",
                              readonly: _vm.value.synced,
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.capacityElem
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.capacityElem.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.capacityElem.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.capacityElem,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.capacityElem.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.capacityMid,
                              label: "Capacity (Middle)",
                              type: "number",
                              min: "0",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.capacityMid
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.capacityMid.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.capacityMid.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.capacityMid,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.capacityMid.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.capacityHigh,
                              label: "Capacity (High)",
                              type: "number",
                              min: "0",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.capacityHigh
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.capacityHigh.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.capacityHigh.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.capacityHigh,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.capacityHigh.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.plateNum,
                              readonly: _vm.value.synced,
                              label: "Plate Number",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.plateNum
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.plateNum.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.plateNum.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.plateNum,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.plateNum.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.vin,
                              readonly: _vm.value.synced,
                              label: "VIN",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.vin
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.vin.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.vin.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.vin,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.vin.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.modelYear,
                              readonly: _vm.value.synced,
                              label: "Model Year",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.modelYear
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.modelYear.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.modelYear.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.modelYear,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.modelYear.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.make,
                              readonly: _vm.value.synced,
                              label: "Make",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.make
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.make.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.make.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.make,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.make.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              value: _vm.value.wheelchairLift,
                              label: "Wheelchair Lift",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.wheelchairLift.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.wheelchairLift.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.wheelchairLift,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.form.wheelchairLift.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              value: _vm.value.camera,
                              label: "Camera",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.camera.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.camera.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.camera, "$model", $$v)
                              },
                              expression: "$v.form.camera.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.wheelchair,
                              label: "# Wheelchair Slots",
                              type: "number",
                              min: "0",
                              readonly: _vm.value.synced,
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.wheelchair
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.wheelchair.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.wheelchair.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.wheelchair,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.wheelchair.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.numSafetyVest,
                              label: "# Safety Vests",
                              type: "number",
                              min: "0",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.numSafetyVest
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.numSafetyVest.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.numSafetyVest.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.numSafetyVest,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.numSafetyVest.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.numFoldDownSeat,
                              label: "# Fold Down Seats",
                              type: "number",
                              min: "0",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.numFoldDownSeat
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.numFoldDownSeat.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.numFoldDownSeat.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.numFoldDownSeat,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.numFoldDownSeat.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.engineType,
                              label: "Engine Type",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.engineType
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.engineType.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.engineType.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.engineType,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.engineType.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.bodyType,
                              label: "Body Type",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.bodyType
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.bodyType.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.bodyType.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.bodyType,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.bodyType.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.gpsDeviceId,
                              label: "GPS Device",
                              disabled: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.gpsDeviceId
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.gpsDeviceId.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.gpsDeviceId.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.gpsDeviceId,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.gpsDeviceId.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("strong", [
                                        _vm._v("Travel Conditions"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.$v.form.travelConditions.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.form.travelConditions,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.form.travelConditions.$model",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { value: 1 },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("div", [_vm._v("In County Only")]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _c("v-radio", {
                                attrs: { value: 2 },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("div", [
                                          _vm._v("Unlimited Mileage"),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _c("v-radio", {
                                attrs: { value: 3 },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("div", [_vm._v("50 Mile Radius")]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              value: _vm.value.description,
                              label: "Description",
                              outlined: "",
                              rows: "1",
                              "auto-grow": "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.description
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.description.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.description.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.description,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.description.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }